import React, { useCallback, useEffect, useState, Suspense } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  ThemeProvider,
  CircularProgress,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../components/ErrorFallback';
import PPSocket from '../classes/SocketClass';
import { CodeType } from './datatypes/codeType';
import { TNodeSource } from '../utils/interfaces';
import {
  convertToString,
  downloadFile,
  formatDate,
  updateDataIfDefault,
} from '../utils/utils';
import { SOCKET_TYPE, customTheme } from '../utils/constants';
import HybridNode2 from '../classes/HybridNode2';
import Socket from '../classes/SocketClass';
import { CustomFunction } from './data/dataFunctions';

// Lazy load MonacoEditor
const MonacoEditor = React.lazy(() =>
  import('react-monaco-editor').then((module) => ({
    default: module.default,
  })),
);

// Loading component
const EditorLoading = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
    <CircularProgress />
  </Box>
);

const outputSocketName = 'output';
const inputSocketName = 'input';
const codeDefaultData =
  '// javascript code editor\n// to run this code, plug it into a CustomFunction node\n(a) => {\nreturn a;\n}';

export class CodeEditor extends HybridNode2 {
  setCodeString = (string) => {};
  public async onExecute(input, output): Promise<void> {
    output[outputSocketName] = input[inputSocketName];
    super.onExecute(input, output);
  }
  getPreferredInputSocketName(): string {
    return inputSocketName;
  }

  public getName(): string {
    return 'Code editor';
  }

  public getDescription(): string {
    return 'Adds a code editor';
  }

  public getTags(): string[] {
    return ['Widget'].concat(super.getTags());
  }

  public getRoundedCorners(): boolean {
    return false;
  }

  protected getDefaultIO(): PPSocket[] {
    return [
      new PPSocket(
        SOCKET_TYPE.OUT,
        outputSocketName,
        new CodeType(),
        undefined,
        true,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        inputSocketName,
        new CodeType(),
        codeDefaultData,
        true,
      ),
    ];
  }

  public onNodeAdded = async (source: TNodeSource): Promise<void> => {
    await super.onNodeAdded(source);
    if (this.initialData) {
      this.setInputData(inputSocketName, this.initialData);
      this.setOutputData(outputSocketName, this.initialData);
    }
  };

  protected async onHybridNodeExit(): Promise<void> {
    await this.executeChildren();
  }
  public getMinNodeWidth(): number {
    return 200;
  }

  public getMinNodeHeight(): number {
    return 150;
  }

  public getDefaultNodeWidth(): number {
    return 400;
  }

  public getDefaultNodeHeight(): number {
    return 300;
  }

  public async populateDefaults(socket: Socket): Promise<void> {
    const dataToUpdate = socket.defaultData;
    await updateDataIfDefault(
      this,
      inputSocketName,
      codeDefaultData,
      dataToUpdate,
    );
    await super.populateDefaults(socket);
  }

  public isCallingMacro(macroName: string): boolean {
    return this.getInputData(inputSocketName)
      ?.replaceAll("'", '"')
      .includes('acro("' + macroName);
  }

  public calledMacroChangedName(oldName: string, newName: string): void {
    if (!this.getInputSocketByName(inputSocketName).links.length) {
      this.setInputData(
        inputSocketName,
        CustomFunction.replaceMacroNameInCode(
          this.getInputData(inputSocketName),
          oldName,
          newName,
        ),
      );
      this.setCodeString(this.getInputData(inputSocketName));
    }
  }

  public parseData(value: any) {
    let dataAsString;
    if (typeof value !== 'string') {
      dataAsString = convertToString(value);
    } else {
      dataAsString = value;
    }
    return dataAsString;
  }
  public loadData() {
    this.setCodeString(this.parseData(this.getInputData(inputSocketName)));
    this.setOutputData(outputSocketName, this.getInputData(inputSocketName));
  }

  onChange = (value: string) => {
    this.setInputData(inputSocketName, value);
    this.setOutputData(outputSocketName, value);
  };

  onExport = (codeString) => {
    downloadFile(
      codeString,
      `${this.name} - ${formatDate()}.txt`,
      'text/plain',
    );
  };

  getParentComponent(props: any): React.ReactElement {
    const node = props.node;
    const EditorComponent = ({ codeString }) => {
      return (
        <Suspense fallback={<EditorLoading />}>
          <MonacoEditor
            width="100%"
            height="100%"
            language="javascript"
            theme="vs-dark"
            value={codeString || ''}
            options={{
              automaticLayout: true,
              lineNumbersMinChars: 4,
              readOnly: false,
              scrollBeyondLastLine: false,
              selectOnLineNumbers: true,
              tabSize: 2,
              wordWrap: 'on',
            }}
            editorWillUnmount={node.loadData()}
            onChange={node.onChange}
          />
        </Suspense>
      );
    };

    const [codeString, setCodeString] = useState<string | undefined>(
      node.parseData(props[inputSocketName]),
    );
    node.setCodeString = setCodeString;

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ThemeProvider theme={customTheme}>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
            }}
          >
            <EditorComponent codeString={codeString} />
            {node.isFocused && (
              <ButtonGroup
                variant="contained"
                size="small"
                sx={{
                  position: 'absolute',
                  bottom: '8px',
                  right: '8px',
                  zIndex: 10,
                }}
              >
                <Button onClick={() => node.onExport(codeString)}>
                  <DownloadIcon sx={{ ml: 0.5, fontSize: '16px' }} />
                </Button>
              </ButtonGroup>
            )}
          </Box>
        </ThemeProvider>
      </ErrorBoundary>
    );
  }
}
