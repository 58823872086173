import React from 'react';
import {
  Box,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PPGraph from './../classes/GraphClass';
import PPNode from './../classes/NodeClass';
import { NodeArrayContainer } from './NodeArrayContainer';
import { customTheme } from './../utils/constants';
import { TRgba } from './../utils/interfaces';
import * as styles from '../utils/style.module.css';

function GraphInspectorHeader(props) {
  return (
    <Box
      id="inspector-header-readonly"
      sx={{
        color: `${TRgba.fromString(
          props.randomMainColor,
        ).getContrastTextColor()}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton
          size="small"
          onClick={props.handlePin}
          sx={{
            color: TRgba.fromString(props.randomMainColor)
              .getContrastTextColor()
              .hex(),
          }}
        >
          {props.isPinned ? (
            <PushPinIcon
              sx={{
                fontSize: '1rem',
              }}
            />
          ) : (
            <PushPinOutlinedIcon
              sx={{
                fontSize: '1rem',
              }}
            />
          )}
        </IconButton>
        <Typography
          sx={{
            pl: 1,
            py: 0.5,
          }}
        >
          {props.graphName}
        </Typography>
      </Box>
    </Box>
  );
}

type GraphInspectorContainerProps = {
  selectedNodes: PPNode[];
  randomMainColor: string;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  filterText: string;
  setFilterText: React.Dispatch<React.SetStateAction<string>>;
  handlePin: any;
  isPinned: boolean;
};

const GraphInspectorContainer: React.FunctionComponent<
  GraphInspectorContainerProps
> = (props) => {
  // it was loading the entire graph from DB when panel was opened before
  /*
  const [graphName, setGraphName] = React.useState('');

  useEffect(() => {
    const graphId = PPGraph.currentGraph?.id;
    if (graphId && graphName) {
      PPStorage.getInstance()
        .getGraphNameFromDB(graphId)
        .then((name) => {
          console.log(name);
          setGraphName(name);
        });
    }
  }, [PPGraph.currentGraph?.id]);
  */

  return (
    <ThemeProvider theme={customTheme}>
      <Stack
        id="inspector-container-graph"
        spacing={1}
        className={`${styles.inspectorContainer}`}
        sx={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
          height: '100%',
          paddingRight: 0,
        }}
      >
        <GraphInspectorHeader
          graphName={PPGraph.currentGraph?.name}
          randomMainColor={props.randomMainColor}
          handlePin={props.handlePin}
          isPinned={props.isPinned}
        />
        <NodeArrayContainer
          graphId={PPGraph.currentGraph?.id}
          selectedNodes={props.selectedNodes}
          randomMainColor={props.randomMainColor}
          filter={props.filter}
          setFilter={props.setFilter}
          filterText={props.filterText}
          setFilterText={props.setFilterText}
        />
      </Stack>
    </ThemeProvider>
  );
};

export default GraphInspectorContainer;
