import { ComputeMessage, ComputeResult } from './compute-worker';

export class PNPWorker {
  private static worker: Worker | undefined;
  private static busy = false;
  private static queue: Array<{
    message: ComputeMessage;
    resolve: (value: ComputeResult) => void;
    reject: (reason: any) => void;
    timer: NodeJS.Timeout;
  }> = [];

  constructor() {
    if (!PNPWorker.worker) {
      PNPWorker.worker = new Worker(
        new URL('compute-worker.ts', import.meta.url),
      );
      PNPWorker.worker.onmessage = PNPWorker.handleMessage.bind(this);
    }
  }

  private static createWorker() {
    PNPWorker.worker = new Worker(
      new URL('compute-worker.ts', import.meta.url),
    );
    PNPWorker.worker.onmessage = PNPWorker.handleMessage;
  }

  private static handleMessage(e: MessageEvent<ComputeResult>) {
    const currentTask = PNPWorker.queue.shift();
    if (currentTask) {
      clearTimeout(currentTask.timer);
      currentTask.resolve(e.data);
    }
    PNPWorker.busy = false;
    PNPWorker.processQueue();
  }

  private static processQueue() {
    if (PNPWorker.queue.length > 0 && !PNPWorker.busy) {
      PNPWorker.busy = true;
      const nextTask = PNPWorker.queue[0];
      PNPWorker.worker!.postMessage(nextTask.message);
    }
  }

  public work(
    message: ComputeMessage,
    timeout: number = 10000,
  ): Promise<ComputeResult> {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        const index = PNPWorker.queue.findIndex(
          (task) => task.message === message,
        );
        if (index !== -1) {
          PNPWorker.queue.splice(index, 1);
        }
        if (PNPWorker.worker) {
          PNPWorker.worker.terminate();
          PNPWorker.createWorker();
        }
        PNPWorker.busy = false;
        reject(new Error('Compute operation timed out'));
        PNPWorker.processQueue();
      }, timeout);

      PNPWorker.queue.push({ message, resolve, reject, timer });
      PNPWorker.processQueue();
    });
  }

  public static terminate() {
    if (PNPWorker.worker) {
      PNPWorker.worker.terminate();
      PNPWorker.worker = undefined;
    }
    PNPWorker.queue.forEach((task) => {
      clearTimeout(task.timer);
      task.reject(new Error('Worker terminated'));
    });
    PNPWorker.queue = [];
    PNPWorker.busy = false;
  }
}
