import React, { forwardRef } from 'react';
import { Box, MenuList, MenuItem } from '@mui/material';
import {
  BeautifulMentionComponentProps,
  BeautifulMentionsMenuProps,
  BeautifulMentionsMenuItemProps,
  createBeautifulMentionNode,
} from 'lexical-beautiful-mentions';
import { TRgba } from '../../../utils/interfaces';
import { MAIN_COLOR } from '../../../utils/constants';

const CustomMentionComponent = forwardRef<
  HTMLSpanElement,
  BeautifulMentionComponentProps<{
    id: string;
    value: string;
    socketname: string;
  }>
>(({ value, data }, ref) => {
  return (
    <Box component="span">
      <Box
        component="span"
        sx={{
          fontSize: '0.5em',
          pr: 0.5,
          background: `${TRgba.fromString(MAIN_COLOR).setAlpha(0.6)}`,
          color: `${TRgba.fromString(MAIN_COLOR).getContrastTextColor()}`,
          px: 0.5,
          py: 0.2,
          mr: 0.25,
          borderRadius: '0.3em',
          fontFamily: 'monospace',
          cursor: 'pointer',
          '&.inline-code-span-focus': {
            outline: `2px solid ${TRgba.fromString(MAIN_COLOR).darken(0.5)}`,
            outlineOffset: '1px',
          },
        }}
      >
        {data.id}
      </Box>
      <Box
        component="span"
        sx={{
          background: `${TRgba.fromString(MAIN_COLOR).setAlpha(0.8)}`,
          color: `${TRgba.fromString(MAIN_COLOR).getContrastTextColor()}`,
          px: 0.5,
          py: 0.2,
          fontFamily: 'monospace',
          cursor: 'pointer',
          '&.inline-code-span-focus': {
            outline: `2px solid ${TRgba.fromString(MAIN_COLOR).darken(0.5)}`,
            outlineOffset: '1px',
          },
        }}
      >
        {value}
      </Box>
    </Box>
  );
});

export const CustomBeautifulMentionNodes = createBeautifulMentionNode(
  CustomMentionComponent,
);

export function InputParameterMenu({
  open,
  loading,
  ...other
}: BeautifulMentionsMenuProps): JSX.Element {
  return (
    <MenuList
      sx={{
        zIndex: 1000,
        bgcolor: 'background.paper',
        width: 'fit-content',
      }}
      {...other}
    />
  );
}
export const InputParameterMenuItem = forwardRef<
  HTMLLIElement,
  BeautifulMentionsMenuItemProps
>(
  (
    {
      selected,
      item: {
        data: { id, socketname },
      },
      ...props
    },
    ref,
  ) => {
    return (
      <MenuItem selected={selected} {...props}>
        <Box
          component="span"
          sx={{
            display: 'inline-block',
            fontSize: '0.75em',
            opacity: 0.4,
            pr: 1,
          }}
        >
          {id}
        </Box>
        <Box
          component="span"
          sx={{
            width: '160px',
            maxWidth: '320px',
            display: 'inline-block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {socketname}
        </Box>
      </MenuItem>
    );
  },
);
