import React from 'react';
import {
  Box,
  ButtonGroup,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import LeftsideContent from './LeftsideContent';
import { customTheme } from './../utils/constants';
import * as styles from './../utils/style.module.css';
import { TRgba } from './../utils/interfaces';
import SocialIcons from '../components/SocialIcons';

function LeftsideHeader(props) {
  return (
    <Box
      sx={{
        color: TRgba.fromString(props.randomMainColor)
          .getContrastTextColor()
          .hex(),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            pl: 1,
            py: 0.5,
          }}
        >
          Plug and Playground
        </Typography>
        <SocialIcons></SocialIcons>
        <ButtonGroup variant="outlined">
          <IconButton
            size="small"
            onClick={props.handlePin}
            sx={{
              color: TRgba.fromString(props.randomMainColor)
                .getContrastTextColor()
                .hex(),
            }}
          >
            {props.isPinned ? (
              <PushPinIcon
                sx={{
                  fontSize: '1rem',
                }}
              />
            ) : (
              <PushPinOutlinedIcon
                sx={{
                  fontSize: '1rem',
                }}
              />
            )}
          </IconButton>
        </ButtonGroup>
      </Box>
    </Box>
  );
}

type LeftsideContainerProps = {
  randomMainColor: string;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  handlePin: any;
  isPinned: boolean;
};

const LeftsideContainer: React.FunctionComponent<LeftsideContainerProps> = (
  props,
) => {
  return (
    <ThemeProvider theme={customTheme}>
      <Stack
        id="inspector-container-left"
        spacing={1}
        className={`${styles.inspectorContainer}`}
        sx={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
          height: 'calc(100vh - 8px)',
          paddingRight: 0,
        }}
      >
        <LeftsideHeader
          randomMainColor={props.randomMainColor}
          handlePin={props.handlePin}
          isPinned={props.isPinned}
        />
        <LeftsideContent
          randomMainColor={props.randomMainColor}
          filter={props.filter}
          setFilter={props.setFilter}
        />
      </Stack>
    </ThemeProvider>
  );
};

export default LeftsideContainer;
