import { CompanionBackend } from './CompanionBackend';
import PPStorage from '../PPStorage';
import { hri } from 'human-readable-ids';
import PPGraph from '../classes/GraphClass';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import { getAllNodesInDetail } from '../nodes/allNodes';
import { Interface } from 'readline';

export const DEFAULT_MODEL = 'claude-3-5-sonnet-latest';

export function getClaudeHeaders(keyName: string) {
  return {
    'Content-Type': 'application/json',
    'Anthropic-Version': '2023-06-01',
    'x-api-key': '${' + keyName + '}',
  };
}

export enum AIConversationSender {
  USER,
  AI,
}

const INSTRUCTION_SEPARATOR = '$$$$$\n\n';

export interface AIConversationMessage {
  sender: AIConversationSender;
  content: string;
  date: Date;
}

export interface ClaudeConversationMessage {
  role: 'user' | 'assistant';
  content: string;
}

export class AIBackend {
  conversations: Record<string, AIConversationMessage[]> = {};

  private static instance: AIBackend = undefined;
  static getInstance() {
    if (this.instance == undefined) {
      this.instance = new AIBackend();
    }
    return this.instance;
  }

  public getConversation(id: string): AIConversationMessage[] {
    if (id in this.conversations) {
      return this.conversations[id];
    } else {
      return [];
    }
  }

  private conversationToClaudeConversation(
    conversation: AIConversationMessage[],
  ): ClaudeConversationMessage[] {
    return conversation.map((entry) => ({
      role: entry.sender === AIConversationSender.USER ? 'user' : 'assistant',
      content: entry.content,
    }));
  }

  public static removeInstructionText(text: string) {
    if (text.includes(INSTRUCTION_SEPARATOR)) {
      return text.split(INSTRUCTION_SEPARATOR)[1];
    } else return text;
  }

  public async sendMessage(
    conversationID: string,
    message: string,
    model: string,
    context: { selectedNodes: boolean; entireGraph: boolean },
    retainConvo = true,
    max_tokens: number = 4096,
  ) {
    const apiKey = await PPStorage.getInstance().getAIAssistantAPIKey();
    let myConvo = this.getConversation(conversationID);
    // when sending a new message, filter our error messages that might have come from before
    for (let i = 0; i < myConvo.length; i++){
      const message = myConvo[i];
      if (message.sender == AIConversationSender.AI && message.content.startsWith("Something went wrong")){
        myConvo.splice(i-1,2);
      }
    }
    
    const convo: ClaudeConversationMessage[] =
      this.conversationToClaudeConversation(myConvo);


    if (retainConvo) {
      let preMessageContent = '';
      if (convo.length == 0) {
        preMessageContent += await this.getConversationStartInstructions();
      }
      if (context.selectedNodes) {
        preMessageContent += this.getSelectedNodesContext();
      }
      if (context.entireGraph) {
        preMessageContent += this.getEntireGraphContext();
      }
      if (preMessageContent.length) {
        preMessageContent += INSTRUCTION_SEPARATOR;
      }
      message = preMessageContent + message;
    }

    console.log('Sending AI message: ' + message);

    const sentDate = new Date();
    const newMessage: ClaudeConversationMessage = {
      content: message,
      role: 'user',
    };
    const myNewMessage = {
      content: message,
      sender: AIConversationSender.USER,
      date: sentDate,
    };
    if (retainConvo) {
      myConvo.push(myNewMessage);
      this.conversations[conversationID] = myConvo;
      InterfaceController.notifyListeners(
        ListenEvent.newAIMessageArrived,
        myConvo,
      );
    }
    convo.push(newMessage);
    const companionSpecific = {
      finalHeaders: getClaudeHeaders(apiKey),
      finalBody: JSON.stringify({
        messages: convo,
        model: model,
        max_tokens,
        temperature: 0.7,
      }),
      finalURL: 'https://api.anthropic.com/v1/messages',
      finalMethod: 'Post',
    };

    const returned =
      await CompanionBackend.getInstance().sendMessage(companionSpecific);

    console.log('returned: ' + JSON.stringify(returned));
    let assistantMessage = '';
    try {
      assistantMessage = JSON.parse(returned.response).content[0].text;
    } catch (error) {
      assistantMessage =
        'Something went wrong when processing!: ' +
        error +
        ', full response: ' +
        JSON.stringify(returned);
    }
    const AIMessage: AIConversationMessage = {
      content: assistantMessage,
      sender: AIConversationSender.AI,
      date: new Date(),
    };

    if (retainConvo) {
      myConvo.push(AIMessage);
      this.conversations[conversationID] = myConvo;
      InterfaceController.notifyListeners(
        ListenEvent.newAIMessageArrived,
        returned,
      );
    }
    return returned;
  }
  async getConversationStartInstructions(): Promise<string> {
    const metaRundown =
      'This is gonna sound pretty confusing, but here is the setup, I am using claude API (you) to help users of my software navigate it, to do this I am starting a new conversation using the Claude API, with a long intro to get Claude (you) to understand the context, I will paste this context here, tell me what you think about this approach, if you think I should do it some other way, or modify the intro text, here it is: ';
    let quickRundown =
      "You are helping a user with their Plug and Playground project. Plug and Playground is a node-based app builder using javascript that allows using pre-made nodes as well as raw javascript in 'Custom Function' nodes, these are arrow functions, for example '(a) => {return a;}', the user can at any time include either currently selected nodes or the entire graph in their messages - or both, you can ask the user to provide these at any time. The user is generally not able to make sense of serialized node data, that data is for you. \n Here is a list of all available nodes: " +
      JSON.stringify(await getAllNodesInDetail());

    quickRundown +=
      '\n\n Node graphs can be copied either in full or in part, here is an example of just a custom function node that links to an Add node, you can use this to make copyable selections for the user, keep in mind that it needs to be deserialized one more time in order to make it copyable for the user: ' +
      SERIALIZED_EXAMPLE;
    quickRundown +=
      '\n\n The user can also drag and drop text/csv/images in the graph';
    quickRundown +=
      '\n\n after this the conversation with the user will be ongoing, don´t acknowledge this initial set of instructions to the user, now starts the users input (and potentially provided context):\n';
    return quickRundown;
  }

  getSelectedNodesContext(): string {
    return (
      '\n\nSelected nodes and links between them in serialized form:\n' +
      JSON.stringify(PPGraph.currentGraph.serializeSelection())
    );
  }

  getEntireGraphContext(): string {
    return (
      '\n\nEntire graph in serialized form:\n' +
      JSON.stringify(PPGraph.currentGraph.serialize())
    );
  }

  public async testConnection(
    selectedModel: string,
  ): Promise<[boolean, string]> {
    try {
      const hasCompanionConnection =
        CompanionBackend.getInstance().getHasConnection();

      if (hasCompanionConnection) {
        const returned = await this.sendMessage(
          hri.random(),
          'random letter',
          selectedModel,
          { selectedNodes: false, entireGraph: false },
          false,
          1,
        );

        console.log(JSON.stringify(returned));
        if (returned.status < 300 && returned.status >= 200) {
          return [
            true,
            'Connection successful! Companion is running and API key is valid.',
          ];
        } else {
          return [
            false,
            'Companion connection is OK but AI backend is not working, do you have a valid API key?',
          ];
        }
      } else {
        return [false, 'Companion is not connected!'];
      }
    } catch (error) {
      return [false, 'Failed to reach companion: ' + error];
    }
  }
}

const SERIALIZED_EXAMPLE = `{
  "version": 0.1,
  "nodes": [
    {
      "id": "grumpy-swan-54",
      "name": "Custom function",
      "type": "CustomFunction",
      "x": -3182.1151341608975,
      "y": -6154.385206923524,
      "width": 160,
      "height": 88,
      "socketArray": [
        {
          "socketType": "in",
          "name": "Code",
          "dataType": "{\"class\":\"CodeType\",\"type\":{}}",
          "data": "(a) => {\n\treturn a;\n}",
          "visible": false
        },
        {
          "socketType": "in",
          "name": "Main Thread",
          "dataType": "{\"class\":\"BooleanType\",\"type\":{}}",
          "data": false,
          "visible": false
        },
        {
          "socketType": "in",
          "name": "a",
          "dataType": "{\"class\":\"AnyType\",\"type\":{}}",
          "data": 0,
          "visible": true
        },
        {
          "socketType": "out",
          "name": "OutData",
          "dataType": "{\"class\":\"NumberType\",\"type\":{\"round\":false,\"minValue\":0,\"maxValue\":100,\"stepSize\":0.01,\"showDetails\":false}}",
          "visible": true
        },
        {
          "socketType": "out",
          "name": "Code",
          "dataType": "{\"class\":\"CodeType\",\"type\":{}}",
          "visible": false
        }
      ],
      "updateBehaviour": {
        "load": false,
        "update": true,
        "interval": false,
        "intervalFrequency": 1000
      },
      "version": 3
    },
    {
      "id": "serious-chicken-26",
      "name": "Add (+)",
      "type": "Add",
      "x": -2966.3390556207005,
      "y": -6177.4292541462655,
      "width": 160,
      "height": 112,
      "socketArray": [
        {
          "socketType": "in",
          "name": "Addend",
          "dataType": "{\"class\":\"NumberType\",\"type\":{\"round\":false,\"minValue\":0,\"maxValue\":100,\"stepSize\":0.01,\"showDetails\":false}}",
          "visible": true
        },
        {
          "socketType": "in",
          "name": "Addend 2",
          "dataType": "{\"class\":\"NumberType\",\"type\":{\"round\":false,\"minValue\":0,\"maxValue\":100,\"stepSize\":0.01,\"showDetails\":false}}",
          "data": 0,
          "visible": true
        },
        {
          "socketType": "out",
          "name": "Added",
          "dataType": "{\"class\":\"NumberType\",\"type\":{\"round\":false,\"minValue\":0,\"maxValue\":100,\"stepSize\":0.01,\"showDetails\":false}}",
          "visible": true
        }
      ],
      "updateBehaviour": {
        "load": false,
        "update": true,
        "interval": false,
        "intervalFrequency": 1000
      }
    }
  ],
  "links": [
    {
      "id": "1234efad-5693-4b1c-a64c-82c99a6d13e3",
      "sourceNodeId": "grumpy-swan-54",
      "sourceSocketName": "OutData",
      "targetNodeId": "serious-chicken-26",
      "targetSocketName": "Addend"
    }
  ]
}`;
