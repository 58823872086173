import InterfaceController, { ListenEvent } from '../InterfaceController';
import PPStorage from '../PPStorage';
import { hri } from 'human-readable-ids';
import { AIBackend } from './AIBackend';

export const COMPANION_ADDRESS = 'http://localhost:6655';
const POLL_INTERVAL = 4000;

export interface CompanionMessage {
  finalHeaders: Record<string, any>;
  finalBody: string;
  finalURL: string;
  finalMethod: string;
}

export class CompanionBackend {
  private static companion: CompanionBackend = undefined;
  hasConnection = false;
  dummyCompanion = false;
  preparedDummyResponses = [];

  private async testConnection(): Promise<boolean> {
    let newResult = false;

    if (this.dummyCompanion) {
      newResult = true;
    } else {
      try {
        // Add AbortController to timeout the fetch request
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 1000); // 1 second timeout

        const response = await fetch(COMPANION_ADDRESS + '/ping', {
          method: 'GET',
          signal: controller.signal,
        });

        clearTimeout(timeoutId);
        newResult = response.ok;
      } catch (error) {
        // Silent failure - connection is simply unavailable
        newResult = false;
      }
    }

    if (this.hasConnection !== newResult) {
      this.hasConnection = newResult;
      InterfaceController.notifyListeners(
        ListenEvent.CompanionConnected,
        newResult,
      );
    }

    return newResult;
  }

  public async sendMessage(message: CompanionMessage): Promise<any> {
    if (this.dummyCompanion) {
      return this.preparedDummyResponses.shift();
    } else {
      try {
        const res = fetch(COMPANION_ADDRESS + '/forward', {
          method: 'Post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(message),
        });
        const companionRes = await (await res).json();
        return companionRes;
      } catch (error) {
        return {
          status: 400,
          response: {
            text: 'Unable to reach companion, is it running at the designated address?',
          },
        };
      }
    }
  }

  private async testConnectionContinously() {
    while (true) {
      await new Promise((r) => setTimeout(r, POLL_INTERVAL));
      this.testConnection();
    }
  }
  private constructor() {
    this.testConnectionContinously();
  }
  public static getInstance() {
    if (CompanionBackend.companion == undefined) {
      this.companion = new CompanionBackend();
    }
    return this.companion;
  }

  public getHasConnection(): boolean {
    return this.hasConnection || this.dummyCompanion;
  }

  public setEnableDummyCompanion(enabled: boolean) {
    this.dummyCompanion = enabled;
  }

  public addDummyResponse(response: any) {
    // when using dummy mode, doing "send message" will instead pop the stack of the dummy responses
    this.preparedDummyResponses.push(response);
  }
}
