import * as PIXI from 'pixi.js';
import PPGraph from './GraphClass';
import PPNode from './NodeClass';
import { MAIN_COLOR } from '../utils/constants';
import { TRgba } from '../utils/interfaces';

export default class Button extends PIXI.Sprite {
  graph: PPGraph;
  node: PPNode;
  up: boolean;
  down: boolean;

  constructor(texture: PIXI.Texture, size = 24) {
    super(texture);

    this.eventMode = 'static';
    this.cursor = 'pointer';
    this.alpha = 0.5;
    this.width = size;
    this.height = size;
    this.tint = new PIXI.Color(
      TRgba.fromString(MAIN_COLOR).darken(0.7).hex(),
    ).toNumber();
    this.addEventListener('pointerover', this.onPointerOver.bind(this));
    this.addEventListener('pointerout', this.onPointerOut.bind(this));
  }

  static async create(imageURL: string, size = 24): Promise<Button> {
    await PIXI.Assets.load(imageURL);
    const texture = PIXI.Texture.from(imageURL);
    return new Button(texture, size);
  }

  // SETUP

  onPointerOver(): void {
    this.alpha = 1.0;
    this.cursor = 'pointer';
  }

  onPointerOut(): void {
    this.alpha = 0.5;
    this.cursor = 'default';
  }
}
