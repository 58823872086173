import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { CompanionBackend } from '../backend/CompanionBackend';
import InterfaceController, { ListenEvent } from '../InterfaceController';

const CompanionComponent: React.FC<{}> = () => {
  const [isRunning, setIsRunning] = useState<boolean>(
    CompanionBackend.getInstance().getHasConnection(),
  );

  useEffect(() => {
    const id = InterfaceController.addListener(
      ListenEvent.CompanionConnected,
      setIsRunning,
    );
    return () => {
      InterfaceController.removeListener(id);
    };
  }, []);

  return (
    <Card sx={{ height: 35, marginBottom: 1 }}>
      <CardContent sx={{ py: 1 }}>
        <Box display="flex" alignItems="center">
          <FiberManualRecordIcon
            sx={{
              color: isRunning ? 'success.main' : 'error.main',
              fontSize: 14,
              mr: 1,
            }}
          />
          <Typography variant="body2">
            Companion {isRunning ? 'running' : 'not running'}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CompanionComponent;
