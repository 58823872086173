import { TRgba } from '../../utils/interfaces';
import { AbstractType, Compatibility, CompatibilityType } from './abstractType';
import { AnyType } from './anyType';

// its a function that will draw onto a container
export class FunctionType extends AbstractType {
  getName(): string {
    return 'Function';
  }

  getComment(commentData: any): string {
    return commentData ? 'Graphics' : 'null';
  }

  getColor(): TRgba {
    return new TRgba(239, 239, 138);
  }

  recommendedOutputNodeWidgets(): string[] {
    return ['CodeEditor'];
  }

  recommendedInputNodeWidgets(): string[] {
    return ['CodeEditor', 'Constant'];
  }
  protected dataIsCompatible(data: any): Compatibility {
    return typeof data === 'function'
      ? new Compatibility(CompatibilityType.Compatible)
      : new Compatibility(CompatibilityType.Incompatible);
  }
}
