import React from 'react';
import { CodeWidget } from '../../widgets';
import {
  AbstractType,
  Compatibility,
  CompatibilityType,
  DataTypeProps,
} from './abstractType';
import { TParseType } from '../../utils/interfaces';
import { SocketParsingWarning } from '../../classes/ErrorClass';

export interface CodeTypeProps extends DataTypeProps {
  dataType: AbstractType;
}

export class CodeType extends AbstractType {
  constructor() {
    super();
  }

  getName(): string {
    return 'Code';
  }

  protected dataIsCompatible(
    data: any,
    convertFrom: AbstractType,
  ): Compatibility {
    switch (typeof data) {
      case 'string':
        return new Compatibility(CompatibilityType.Preferred);
      case 'object':
        return new Compatibility(CompatibilityType.Compatible);
      default:
        return new Compatibility(CompatibilityType.Incompatible);
    }
  }

  parse(data: any): TParseType {
    let value = data;
    let warnings = [];
    if (typeof data !== 'string') {
      value = JSON.stringify(data);
    }
    return { value, warnings };
  }

  getInputWidget = (props: CodeTypeProps): any => {
    props.dataType = this;
    return <CodeWidget {...props} />;
  };

  getDefaultWidgetSize(): any {
    return {
      w: 4,
      h: 6,
      minW: 2,
      minH: 2,
    };
  }

  getDefaultValue(): any {
    return '';
  }

  recommendedOutputNodeWidgets(): string[] {
    return ['CodeEditor'];
  }

  recommendedInputNodeWidgets(): string[] {
    return ['CodeEditor', 'Constant'];
  }
}
