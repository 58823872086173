/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { SelectWidget } from '../../widgets';
import {
  AbstractType,
  Compatibility,
  CompatibilityType,
  DataTypeProps,
} from './abstractType';
import { EnumStructure } from './enumType';
import PPNode from '../../classes/NodeClass';
import { AnyType } from './anyType';

export interface DynamicEnumTypeProps extends DataTypeProps {
  dataType: DynamicEnumType;
  options: EnumStructure;
  onChange?: (value: string) => void;
  setOptions;
}

export class DynamicEnumType extends AbstractType {
  getOptions = () => [];
  onChange = () => {};
  showAsButtons: boolean;

  constructor(getOptions, onChange, showAsButtons = false) {
    super();
    this.getOptions = getOptions;
    this.onChange = onChange;
    this.showAsButtons = showAsButtons;
  }
  getName(): string {
    return 'Dynamic Enum';
  }

  protected dataIsCompatible(
    data: any,
    convertFrom: AbstractType = new AnyType(),
  ): Compatibility {
    return new Compatibility(
      typeof data == 'string'
        ? CompatibilityType.Compatible
        : CompatibilityType.Incompatible,
    );
  }

  getDefaultValue(): any {
    return '';
  }

  getInputWidget = (props: DynamicEnumTypeProps): any => {
    props.dataType = this;
    props.options = this.getOptions();
    props.onChange = this.onChange;
    return <SelectWidget {...props} />;
  };

  getDefaultWidgetSize(): any {
    return {
      w: 2,
      h: 2,
      minW: 1,
      minH: 2,
    };
  }

  recommendedOutputNodeWidgets(): string[] {
    return ['WidgetDropdown'];
  }

  recommendedInputNodeWidgets(): string[] {
    return ['CodeEditor', 'Constant'];
  }

  configureOnLoad(): boolean {
    return false;
  }
}
