import Socket from '../../classes/SocketClass';
import { NODE_SOURCE, SOCKET_TYPE } from '../../utils/constants';
import { TNodeSource, TRgba } from '../../utils/interfaces';
import { arrayEntryToSelectedValue } from '../data/dataFunctions';
import { BooleanType } from '../datatypes/booleanType';
import {
  GraphInputPointX,
  GraphInputPointXY,
  GraphInputXType,
  GraphInputXYType,
} from '../datatypes/graphInputType';
import InputArrayKeysType, {
  ENTIRE_OBJECT_NAME,
  INDEX_NAME,
} from '../datatypes/inputArrayKeysType';
import { JSONArrayType } from '../datatypes/jsonArrayType';
import { NumberType } from '../datatypes/numberType';
import { TwoDVectorType } from '../datatypes/twoDVectorType';
import { TypeConversionNode } from './conversionBase';

const inputJSONArrayName = 'JSON Array';
const inputLabelName = 'Label';
const inputValueName = 'Value';
const inputOverrideColor = 'Override Color';
const inputColorName = 'Color';

const inputValue1Name = 'Value 1';
const inputValue2Name = 'Value 2';

const outputGraphableName = 'Graphable';

function fishOutGraphValue(name: string, entry: any, index: number) {
  let value = arrayEntryToSelectedValue(entry, name, index);
  // if strings, convert
  if (typeof value == 'string') {
    value = parseFloat(value.trim());
  }
  return value;
}

interface GraphValueSuggestions {
  Color: string;
  Name: string;
  Values: string[];
}

// this has gotten quite complex but not sure it can be made simpler, it is fairly smart
function getDefaultGraphValues(
  input: any,
  desiredValues: number,
): GraphValueSuggestions {
  // best better hope there is an array coming in, otherwise just give up
  let foundValues = [];
  let foundColor = undefined;
  let foundName = undefined;
  if (Array.isArray(input)) {
    const inputArray: any[] = input;
    if (inputArray.length > 0) {
      const entry = inputArray[0];
      if (typeof entry == 'object') {
        const keys = Object.keys(entry);
        // first try to find value
        if (entry?.Value !== undefined) {
          foundValues.push('Value');
        }
        while (foundValues.length < desiredValues) {
          const foundNumberField = keys.find(
            (key) =>
              typeof entry[key] == 'number' && !foundValues.includes(key),
          );
          if (foundNumberField !== undefined) {
            // good
            foundValues.push(foundNumberField);
            continue;
          } else {
            // no number for us here, well then lets try to find a string that can be converted to a number
            const stringFields = keys.filter(
              (key) =>
                typeof entry[key] == 'string' && !foundValues.includes(key),
            );
            for (let i = 0; i < stringFields.length; i++) {
              const attemptedNumber = parseFloat(entry[stringFields[i]].trim());
              if (!Number.isNaN(attemptedNumber)) {
                foundValues.push(stringFields[i]);
                continue;
              }
            }
            // give up
            break;
          }
        }

        // then try to find "Name"
        if (entry?.Name !== undefined) {
          foundName = 'Name';
        } else if (entry?.Label !== undefined) {
          foundName = 'Label';
        } else {
          // try to find some label that is a string and not the same as an existing value
          const interestingField = keys.find(
            (key) =>
              typeof entry[key] == 'string' && !foundValues.includes(key),
          );
          if (interestingField !== undefined) {
            foundName = interestingField;
          }
        }
        // last, color
        if (entry?.Color !== undefined) {
          foundColor = 'Color';
        }
      } else {
        // not objects, so use entire thing here (hope its a number or can be converted to one)
        foundValues.push(INDEX_NAME);
      }
    }
  }

  return { Color: foundColor, Name: foundName, Values: foundValues };
}

export abstract class JSONArrayToGraphInputX extends TypeConversionNode {
  public getName(): string {
    return 'Convert JSON Array to Graph Input X';
  }
  public getDescription(): string {
    return 'Prepares an input JSON array to be put on a one dimension graph';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, inputJSONArrayName, new JSONArrayType()),
      new Socket(
        SOCKET_TYPE.IN,
        inputValueName,
        new InputArrayKeysType(inputJSONArrayName, this.id, true, false),
      ),
      new Socket(
        SOCKET_TYPE.IN,
        inputLabelName,
        new InputArrayKeysType(inputJSONArrayName, this.id, true, false),
      ),
      new Socket(SOCKET_TYPE.IN, inputOverrideColor, new BooleanType(), false),
      Socket.getOptionalVisibilitySocket(
        SOCKET_TYPE.IN,
        inputColorName,
        new InputArrayKeysType(inputJSONArrayName, this.id, true, false),
        ENTIRE_OBJECT_NAME,
        () => this.getInputData(inputOverrideColor),
      ),

      new Socket(SOCKET_TYPE.OUT, outputGraphableName, new GraphInputXType()),
    ];
  }

  protected autoSetDefaultValues(): void {
    const currentInput = this.getInputData(inputJSONArrayName);
    const foundSuggestions = getDefaultGraphValues(currentInput, 1);
    if (foundSuggestions.Name !== undefined) {
      this.setInputData(inputLabelName, foundSuggestions.Name);
    }
    if (foundSuggestions.Color !== undefined) {
      this.setInputData(inputColorName, foundSuggestions.Color);
    }
    if (foundSuggestions.Values.length > 0) {
      this.setInputData(inputValueName, foundSuggestions.Values[0]);
    }
  }

  protected async onExecute(
    inputObject: unknown,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    const graphableObjects: GraphInputPointX[] = [];
    // assume input is an array
    const inputArray: any[] = inputObject[inputJSONArrayName];
    inputArray.forEach((entry, index) => {
      const label = arrayEntryToSelectedValue(
        entry,
        inputObject[inputLabelName],
        index,
      );
      const value = fishOutGraphValue(
        inputObject[inputValueName],
        entry,
        index,
      );

      let color = undefined;
      if (inputObject[inputOverrideColor]) {
        color = arrayEntryToSelectedValue(
          entry,
          inputObject[inputColorName],
          index,
        );
      }
      if (value !== undefined && !isNaN(value)) {
        graphableObjects.push({ Value: value, Name: label, Color: color });
      }
    });
    outputObject[outputGraphableName] = graphableObjects;
  }
}

export abstract class JSONArrayToGraphInputXY extends TypeConversionNode {
  public getName(): string {
    return 'Convert JSON Array to Graph Input XY';
  }
  public getDescription(): string {
    return 'Prepares an input JSON array to be put on a two dimensional graph';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, inputJSONArrayName, new JSONArrayType()),
      new Socket(
        SOCKET_TYPE.IN,
        inputValue1Name,
        new InputArrayKeysType(inputJSONArrayName, this.id, true, false),
      ),
      new Socket(
        SOCKET_TYPE.IN,
        inputValue2Name,
        new InputArrayKeysType(inputJSONArrayName, this.id, true, false),
      ),
      new Socket(
        SOCKET_TYPE.IN,
        inputLabelName,
        new InputArrayKeysType(inputJSONArrayName, this.id, true, false),
      ),
      new Socket(SOCKET_TYPE.IN, inputOverrideColor, new BooleanType(), false),
      Socket.getOptionalVisibilitySocket(
        SOCKET_TYPE.IN,
        inputColorName,
        new InputArrayKeysType(inputJSONArrayName, this.id, true, false),
        ENTIRE_OBJECT_NAME,
        () => this.getInputData(inputOverrideColor),
      ),

      new Socket(SOCKET_TYPE.OUT, outputGraphableName, new GraphInputXYType()),
    ];
  }

  protected autoSetDefaultValues(): void {
    const currentInput = this.getInputData(inputJSONArrayName);
    const foundSuggestions = getDefaultGraphValues(currentInput, 1);
    if (foundSuggestions.Name !== undefined) {
      this.setInputData(inputLabelName, foundSuggestions.Name);
    }
    if (foundSuggestions.Color !== undefined) {
      this.setInputData(inputColorName, foundSuggestions.Color);
    }
    if (foundSuggestions.Values.length > 0) {
      this.setInputData(inputValue1Name, foundSuggestions.Values[0]);
    }
    if (foundSuggestions.Values.length > 1) {
      this.setInputData(inputValue2Name, foundSuggestions.Values[1]);
    }
  }

  protected async onExecute(
    inputObject: unknown,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    const graphableObjects: GraphInputPointXY[] = [];
    // assume input is an array
    const inputArray: any[] = inputObject[inputJSONArrayName];
    inputArray.forEach((entry, index) => {
      const label = arrayEntryToSelectedValue(
        entry,
        inputObject[inputLabelName],
        index,
      );
      let value1 = fishOutGraphValue(
        inputObject[inputValue1Name],
        entry,
        index,
      );
      let value2 = fishOutGraphValue(
        inputObject[inputValue2Name],
        entry,
        index,
      );

      let color = undefined;
      if (inputObject[inputOverrideColor]) {
        color = arrayEntryToSelectedValue(
          entry,
          inputObject[inputColorName],
          index,
        );
      }
      let size = undefined;

      if (
        value1 !== undefined &&
        value2 !== undefined &&
        !isNaN(value1) &&
        !isNaN(value2)
      ) {
        graphableObjects.push({
          Value1: value1,
          Value2: value2,
          Name: label,
          Color: color,
          Size: entry['Size'],
        });
      }
    });
    outputObject[outputGraphableName] = graphableObjects;
  }
}
