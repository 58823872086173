import React from 'react';
import { ArrayWidget } from '../../widgets';
import { SocketParsingWarning } from '../../classes/ErrorClass';
import { TParseType, TRgba } from '../../utils/interfaces';
import { AbstractType, Compatibility, DataTypeProps } from './abstractType';
import { AnyType } from './anyType';

export interface ArrayTypeProps extends DataTypeProps {
  dataType: AbstractType;
}

export class ArrayType extends AbstractType {
  constructor() {
    super();
  }
  getName(): string {
    return 'Array';
  }

  getInputWidget = (props: DataTypeProps): any => {
    props.dataType = this;
    return <ArrayWidget {...props} />;
  };

  getDefaultWidgetSize(): any {
    return {
      w: 4,
      h: 6,
      minW: 2,
      minH: 2,
    };
  }

  getDefaultValue(): any {
    return [];
  }

  getColor(): TRgba {
    return new TRgba(204, 153, 255);
  }

  getMetaText(data: any): string {
    return (
      '(' + (Array.isArray(data) ? data.length.toString() : 'Invalid') + ')'
    );
  }
  protected dataIsCompatible(
    data: any,
    convertFrom: AbstractType = new AnyType(),
  ): Compatibility {
    return AbstractType.warningsToCompatibility(this.parse(data).warnings);
  }

  parse(data: any): TParseType {
    let parsedData;
    const warnings: SocketParsingWarning[] = [];

    if (Array.isArray(data)) {
      parsedData = data;
    } else if (typeof data === 'string') {
      try {
        parsedData = JSON.parse(data);
        if (!Array.isArray(parsedData)) {
          parsedData = [data];
        }
      } catch (error) {
        parsedData = [data];
      }
    }
    if (parsedData == undefined) {
      parsedData = [];
      warnings.push(
        new SocketParsingWarning(
          'Not an array and cannot be converted to one. [] is returned',
        ),
      );
    }

    return {
      value: parsedData,
      warnings: warnings,
    };
  }

  recommendedOutputNodeWidgets(): string[] {
    return [
      'MapNode',
      'Filter',
      'ArrayLength',
      'ArraySlice',
      'ConcatenateArrays',
      'ArrayGet',
    ];
  }

  recommendedInputNodeWidgets(): string[] {
    return [
      'CodeEditor',
      'Constant',
      'RandomArray',
      'RangeArray',
      'ColorArray',
      'ArrayCreate',
      'WidgetRadio',
    ];
  }
}
